import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link, Navigate } from 'react-router-dom'

import { login, useAppDispatch, useAppSelector } from '@/store'

import { REPORTS_PATH, TERMINALS_PATH, loginField, passField } from '@/utils'

import Arrow from '../assets/images/arrow-right.svg'

type Inputs = {
	login: string
	password: string
}

export function Login() {
	const { isAuth, role } = useAppSelector((state) => state.auth)
	const dispatch = useAppDispatch()

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<Inputs>()
	const onSubmit: SubmitHandler<Inputs> = (data) => {
		dispatch(login({ login: data.login, password: data.password }))
	}

	if (isAuth) {
		return (
			<Navigate to={role !== 'Statistician' ? TERMINALS_PATH : REPORTS_PATH} />
		)
	}

	return (
		<div className="login">
			<form className="login__inner" onSubmit={handleSubmit(onSubmit)}>
				<div className="login__title">Авторизация</div>
				<div className="login__inner-input">
					<div className="input">
						<label className="input__label" htmlFor="name">
							Имя пользователя
						</label>
						<Controller
							name="login"
							control={control}
							rules={loginField}
							render={({ field }) => (
								<Input placeholder="Имя" size="large" id="name" {...field} />
							)}
						/>
						{errors?.login && (
							<div className="input__error">
								{errors.login?.message ? errors.login?.message : 'Ошибка'}
							</div>
						)}
					</div>
					<div className="input">
						<label className="input__label" htmlFor="pass">
							Пароль
						</label>
						<Controller
							name="password"
							control={control}
							rules={passField}
							render={({ field }) => (
								<Input.Password
									{...field}
									placeholder="Ваш пароль"
									size="large"
									id="pass"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
								/>
							)}
						/>
						{errors?.password && (
							<div className="input__error">
								{errors.password?.message ? errors.password?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
				<Button
					className="login__btn"
					type="primary"
					size="large"
					htmlType="submit"
				>
					Войти
					<img className="login__btn-icon" src={Arrow} alt="Arrow" />
				</Button>
				<div className="login__bottom">
					<div className="login__link-wrap">
						Забыли пароль?{' '}
						<Link to={''} className="login__link">
							Восстановить
						</Link>
					</div>
				</div>
			</form>
		</div>
	)
}
