import { Button, notification } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	setAppealId,
	setCallRecordId,
	setIsCalling,
	setTypeCall,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { ICallBody } from '@/utils'

import { API_URL } from '@/configs'

import { terminalCallAPI } from '@/api'

interface Props {
	children: JSX.Element
}

export const CallProvider = ({ children }: Props) => {
	const { userId } = useAppSelector((state) => state.auth)
	const { isCalling } = useAppSelector((state) => state.call)
	const dispatch = useAppDispatch()
	const router = useNavigate()
	const notificationRef = useRef<any>(null)
	const [audio] = useState(new Audio('/call-sound.mp3'))

	useEffect(() => {
		const socket = new WebSocket(
			`${API_URL}/terminal_call/subscribe?UserId=${userId}`
		)

		socket.onmessage = async (msg: any) => {
			if (msg.data !== 'null') {
				const data: ICallBody = JSON.parse(msg.data)

				if (!isCalling && !notificationRef.current) {
					audio.loop = true // Звук будет играть в цикле
					audio.play().catch((error) => {
						console.error('Ошибка при воспроизведении звука:', error)
					})

					dispatch(setIsCalling(true))
					dispatch(setTypeCall('terminal'))

					notificationRef.current = notification.open({
						key: 'call_notification',
						message: 'Звонок поступил!',
						description: (
							<div>
								<p>
									У вас новый звонок от <b>{data.terminal.name}</b>
								</p>
								<p>
									<b>{data.terminal.address}</b>
								</p>
								<p>Хотите принять или отклонить?</p>
							</div>
						),
						btn: (
							<>
								<Button
									key="accept"
									type="primary"
									onClick={() => handleAccept(data.terminal.id)}
								>
									Принять
								</Button>
								<Button key="reject" onClick={handleReject}>
									Отклонить
								</Button>
							</>
						),
						onClose: () => {
							notificationRef.current = null
							dispatch(setIsCalling(false))
						},
						duration: 0,
					})
				}
			} else {
				closeNotification()
				audio.pause()
			}
		}

		return () => {
			if (socket?.readyState !== 3) socket.close()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCalling, userId, dispatch])

	const closeNotification = () => {
		notification.destroy('call_notification')
		notificationRef.current = null
		dispatch(setIsCalling(false))
	}

	const handleAccept = async (id: string) => {
		dispatch(setIsCalling(false))
		audio.pause()
		const { data: accept_data } = await terminalCallAPI.accept_call()
		dispatch(setCallRecordId(accept_data?.callRecordId))
		dispatch(setAppealId(accept_data?.appealId))
		router(`/terminals/${id}?session=${accept_data.appealId}`, {
			replace: true,
		})

		closeNotification()
	}

	const handleReject = () => {
		dispatch(setIsCalling(false))
		terminalCallAPI.reject_call()

		closeNotification()
	}

	return <>{children}</>
}
