import { UploadOutlined } from '@ant-design/icons'
import { Button, Modal, Upload, message, notification } from 'antd'
import { memo, useCallback, useEffect, useState } from 'react'

import { Equipment, FileUploadModal } from '@/components'

import { loadingStatus, useAppDispatch, useAppSelector } from '@/store'

import { nameDevice } from '@/utils'

import { backgroundVideosAPI, terminalAPI } from '@/api'

import TerminalMaintenance from './TerminalMaintenance'
import { TerminalSettingsMain } from './TerminalSettingsMain'

export const TerminalSettings = memo(({ id }: { id: string }) => {
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const terminalConfig = useAppSelector(
		(state) => state.terminals.currentTerminalConfig
	)

	const DeviceSettings = memo(() => (
		<div className="terminalSettings__config-device">
			<div className="terminalSettings__title _h4">Настройка устройств</div>
			<div className="terminalSettings__config-device-item">
				{terminalInfo?.devices?.map((device) => (
					<Equipment
						TerminalId={id}
						key={device.id}
						title={nameDevice[device.type]?.name}
						status={device.status}
						icon={nameDevice[device.type]?.icon}
						setting
					/>
				))}
			</div>
		</div>
	))

	const downloadFile = async () => {
		try {
			const response = await terminalAPI.getConfig({ TerminalId: id })

			const contentDisposition = response.headers['Content-Disposition']

			let fileName = 'config.json'

			if (contentDisposition) {
				const fileNameMatch = contentDisposition.match(
					/filename\*?=(?:UTF-8''([\w%\-.]+)|"([^"]+)")/
				)
				fileName = fileNameMatch
					? decodeURIComponent(fileNameMatch[1] || fileNameMatch[2])
					: fileName
			}

			const blob = new Blob([response.data])
			const url = window.URL.createObjectURL(blob)

			const link = document.createElement('a')
			link.href = url
			link.download = fileName
			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error('Ошибка при скачивании файла:', error)
			notification.error({ message: 'Ошибка при скачивании файла' })
		}
	}

	return (
		<div className="terminalSettings">
			{terminalInfo && terminalConfig && (
				<TerminalSettingsMain terminalInfo={terminalInfo} />
			)}
			<div className="terminalSettings__content">
				<div className="terminalSettings__item">
					<div className="terminalSettings__config">
						<div className="terminalSettings__title _h4">
							Конфигурация АСТИП
						</div>
						<FileUploadConfig terminalId={id} />
						<Button
							className="terminalSettings__btn-down"
							type="primary"
							onClick={downloadFile}
						>
							Скачать файл конфигурации
						</Button>
					</div>
					<div className="terminalSettings__reklama">
						<FileUploadRRSection
							title="Загрузить рекламный ролик в раздел 1"
							type={1}
							terminalId={id}
						/>
						<FileUploadRRSection
							title="Загрузить рекламный ролик в раздел 2"
							type={2}
							terminalId={id}
						/>
						<FileUploadSection title="Загрузить заставку" terminalId={id} />
					</div>
				</div>
				<div className="terminalSettings__item">
					<DeviceSettings />
					{terminalInfo && (
						<TerminalMaintenance terminalId={id} terminalInfo={terminalInfo} />
					)}
				</div>
			</div>
		</div>
	)
})

const FileUploadRRSection = memo(
	({
		title,
		type,
		terminalId,
	}: {
		title: string
		type: 1 | 2
		terminalId: string
	}) => {
		const [open, setOpen] = useState(false)
		const [list, setList] = useState<any[]>([])
		const [active, setActive] = useState<any>(null)

		const closeModal = useCallback(() => {
			setOpen(false)
		}, [])

		const getList = async () => {
			try {
				const { data } = await backgroundVideosAPI.getAdvertisement({
					type,
				})
				setList(data)
				setActive(
					data.find((item) =>
						item.astiPs.some((astip) => astip.id === terminalId)
					)
				)
			} catch (e: any) {
				message.error(e.message)
			}
		}

		useEffect(() => {
			getList()
		}, [])

		return (
			<>
				<div className="terminalSettings__rekalame-item">
					<div className="terminalSettings__subtitle _h4">{title}</div>
					<div className="terminalSettings__inner">
						<Button onClick={() => setOpen(true)}>Выбрать</Button>
					</div>
					<div className="terminalSettings__list">
						{active ? (
							<div className="terminalSettings__list-active" key={active.id}>
								<p>
									Выбрано: <b>{active.name}</b>
								</p>
							</div>
						) : (
							<p>Рекламный ролик выбран</p>
						)}
					</div>
				</div>
				<Modal
					title={`Загрузить рекламный ролик в раздел ${type}`}
					open={open}
					onOk={closeModal}
					onCancel={closeModal}
					footer={null}
				>
					<FileUploadModal
						list={list}
						terminalId={terminalId}
						getList={getList}
						type="rr"
						AdType={type}
					/>
				</Modal>
			</>
		)
	}
)

const FileUploadSection = memo(
	({ title, terminalId }: { title: string; terminalId: string }) => {
		const [open, setOpen] = useState(false)
		const [list, setList] = useState<any[]>([])
		const [active, setActive] = useState<any>(null)

		const closeModal = useCallback(() => {
			setOpen(false)
		}, [])

		const getList = async (): Promise<void> => {
			try {
				const { data } = await backgroundVideosAPI.getScreenSavers()
				setList(data)
				setActive(
					data.find((item) =>
						item.astiPs.some((astip) => astip.id === terminalId)
					)
				)
			} catch (e: any) {
				message.error(e.message)
			}
		}

		useEffect(() => {
			getList()
		}, [])

		return (
			<>
				<div className="terminalSettings__config-download">
					<div className="terminalSettings__title _h4">{title}</div>
					<div className="terminalSettings__inner">
						<Button onClick={() => setOpen(true)}>Выбрать</Button>
					</div>
					<div className="terminalSettings__list">
						{active ? (
							<div className="terminalSettings__list-active" key={active.id}>
								<p>
									Выбрано: <b>{active.name}</b>
								</p>
							</div>
						) : (
							<p>Заставка выбрана</p>
						)}
					</div>
				</div>
				<Modal
					title="Загрузить заставку"
					open={open}
					onOk={closeModal}
					onCancel={closeModal}
					footer={null}
				>
					<FileUploadModal
						list={list}
						terminalId={terminalId}
						getList={getList}
						type="screensaver"
					/>
				</Modal>
			</>
		)
	}
)

const FileUploadConfig = memo(({ terminalId }: { terminalId: string }) => {
	const dispatch = useAppDispatch()
	const [open, setOpen] = useState(false)

	const closeModal = useCallback(() => {
		setOpen(false)
	}, [])

	return (
		<>
			<Button onClick={() => setOpen(true)}>Загрузить конфигурацию</Button>
			<Modal
				title="Загрузить конфигурацию"
				open={open}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
			>
				<Upload
					beforeUpload={async (file) => {
						dispatch(loadingStatus(true))
						try {
							await terminalAPI.setConfig({
								TerminalId: terminalId,
								Config: file,
							})
							notification.success({
								message: 'Успешно',
								description: 'Конфигурация успешно загружена',
							})
						} catch (error: any) {
							notification.error({ message: error.message })
						} finally {
							dispatch(loadingStatus(false))
						}
						return false
					}}
					showUploadList={false}
				>
					<Button icon={<UploadOutlined />}>
						Загрузить новую конфигурацию
					</Button>
				</Upload>
			</Modal>
		</>
	)
})
