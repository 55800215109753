import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

interface ProtectedRouteProps {
	children: React.ReactNode
	allowedRoles: string[]
}

export const ProtectedRoute = ({
	children,
	allowedRoles,
}: ProtectedRouteProps) => {
	const { role } = useAppSelector((state) => state.auth)

	if (!role || !allowedRoles.includes(role)) {
		return <Navigate to="/404" />
	}

	return <>{children}</>
}
