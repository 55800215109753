import { Button, DatePicker, Segmented } from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'

import {
	WorkTimeType,
	openErrorNotification,
	openSuccessNotification,
} from '@/utils'

import { terminalAPI } from '@/api'

const { RangePicker } = DatePicker
const EditDayWorkTime = ({
	day,
	close,
	terminalId,
	currentExclusion,
	fetc,
}: {
	terminalId: string
	day: null | Dayjs
	close: any
	currentExclusion: WorkTimeType | null
	fetc: any
}) => {
	const [newTime, setNewTime] = useState<null | any>(null)
	const [currentChange, setCurrentChange] = useState<string>('Поменять время')
	const [typeDay, setTypeDay] = useState<boolean>(false)
	const dispatch = useAppDispatch()

	const onChangeTime = (dates: any, dateString: any) => {
		setNewTime(dateString)
	}

	const onDelete = async () => {
		dispatch(loadingStatus(true))
		try {
			if (currentExclusion) {
				await terminalAPI.removeDateExclusions({
					ExclusionId: currentExclusion?.id,
				})
			}
			close()
			fetc()
			openSuccessNotification('Данное правило удалено успешно')
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка при удаление правила')
		} finally {
			dispatch(loadingStatus(false))
		}
	}
	const onSave = async () => {
		dispatch(loadingStatus(true))
		try {
			if (day) {
				if (currentChange === 'Поменять тип дня') {
					await terminalAPI.addDateExclusions({
						terminalId,
						date: day.format('YYYY-MM-DD'),
					})
					openSuccessNotification('Данное правило добавлено успешно')
				}
				if (currentChange === 'Поменять время') {
					await terminalAPI.addDateExclusions({
						terminalId,
						date: day.format('YYYY-MM-DD'),
						DateStart: dayjs(newTime[0], 'HH:mm:ss').format('HH:mm:ss'),
						DateEnd: dayjs(newTime[1], 'HH:mm:ss').format('HH:mm:ss'),
					})
					openSuccessNotification('Данное правило добавлено успешно')
				}
			}
			close()
			fetc()
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка при добавление правила')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const onChangeTypeDay = () => {
		setTypeDay((old) => !old)
	}
	useEffect(() => {
		return () => {
			setNewTime(null)
			setTypeDay(false)
		}
	}, [])
	return (
		<div className="work-time__modal">
			<div className="work-time__modal-about">
				Вы редактируете: {day && day.format('YYYY-MM-DD')}
			</div>
			{currentExclusion && (
				<div className="work-time__modal-edit-text">
					Данный день уже был отредактирован,{' '}
					<span>чтобы изменить необходимо удалить изменения </span>
				</div>
			)}
			{currentExclusion?.startTime && (
				<div className="work-time__modal-time">
					<div className="work-time__modal-time-title">
						Текущее время работы:{' '}
						{dayjs(currentExclusion.startTime, 'HH:mm:ss').format('HH:mm:ss')} -
						{dayjs(currentExclusion.endTime, 'HH:mm:ss').format('HH:mm:ss')}
					</div>
				</div>
			)}

			{day && (
				<>
					{currentExclusion === null ? (
						<>
							<Segmented<string>
								className="work-time__modal-segmented"
								options={['Поменять время', 'Поменять тип дня']}
								onChange={(value) => {
									setCurrentChange(value)
									setTypeDay(false)
								}}
							/>
							{currentChange === 'Поменять время' && (
								<div className="work-time__modal-time-edit">
									<div className="work-time__modal-time-edit-title">
										Изменить время работы
									</div>
									<RangePicker
										picker="time"
										id={{
											start: 'startInput',
											end: 'endInput',
										}}
										onChange={onChangeTime}
									/>
								</div>
							)}

							{currentChange === 'Поменять тип дня' && (
								<div className="work-time__modal-time-edit">
									<div className="work-time__modal-time-edit-title">
										Изменить тип дня (Применится стандартное время)
									</div>
									{/* {currentExclusion && currentExclusion.startTime === null && (
										<div className="work-time__modal-fulltime-warning">
											!Тип дня уже был изменен на{' '}
											{day.day() === 6 || day.day() === 0
												? 'Будни'
												: 'Выходной'}
											. Чтобы отменить нажмите "Удалить изменения"
										</div>
									)} */}

									<Button
										className="work-time__modal-fulltime-btn"
										onClick={onChangeTypeDay}
										type={typeDay ? 'primary' : 'default'}
									>
										{day.day() === 6 || day.day() === 0
											? 'Сделать будни'
											: 'Сделать выходной'}
									</Button>
								</div>
							)}
						</>
					) : (
						' '
					)}
				</>
			)}

			<div className="work-time__modal-footer">
				<Button onClick={close}>Закрыть</Button>
				{currentExclusion && (
					<Button onClick={onDelete} danger>
						Удалить изменения
					</Button>
				)}
				<Button onClick={onSave} type="primary">
					Сохранить
				</Button>
			</div>
		</div>
	)
}

export default EditDayWorkTime
