import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { licensesAPI } from '@/api'

type licenseType = {
	clientName: string
	expirationDate: string
	maxTerminals: number
	isValid: boolean
	isActive: boolean
}

export default function System() {
	const [info, setInfo] = useState<null | licenseType>(null)

	const dispatch = useAppDispatch()

	const fetch = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await licensesAPI.getInfo()
			setInfo(data)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка получения лицензии')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const onSend = async (file: any, fileList: any) => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await licensesAPI.uploadInfo({ File: file })
			if (data) {
				openSuccessNotification('Успешная загрузка документа')
			} else {
				openErrorNotification('Ошибка установки')
			}

			fetch()
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка загрузки документа')
		} finally {
			dispatch(loadingStatus(false))
		}
		return false
	}

	useEffect(() => {
		fetch()
	}, [])

	return (
		<div className="system">
			<div className="system__title">Информация о лицензии</div>
			<ul className="system__list">
				<li className="system__item">
					<span>Имя клиента: </span>
					<span>{info?.clientName}</span>
				</li>
				<li className="system__item">
					<span>Срок годности: </span>
					<span>
						{info?.expirationDate
							? moment(info.expirationDate).format('YYYY-MM-DD')
							: 'Нет данных'}
					</span>
				</li>
				<li className="system__item">
					<span>Максимум терминалов: </span>
					<span>{info?.maxTerminals}</span>
				</li>
				<li className="system__item">
					<span>Валидный: </span>
					<span>{info?.isValid ? 'Да' : 'Нет'} </span>
				</li>
				<li className="system__item">
					<span>Активный: </span>
					<span>{info?.isActive ? 'Да' : 'Нет'} </span>
				</li>
			</ul>
			<div className="system__title">Загрузить файл</div>
			<Upload
				beforeUpload={(file, fileList) => onSend(file, fileList)}
				name={'file'}
				maxCount={1}
				accept={'.json'}
				onRemove={() => false}
				showUploadList={false}
			>
				<Button icon={<UploadOutlined />}>Нажите чтобы загрузить</Button>
			</Upload>
		</div>
	)
}
