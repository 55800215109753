import { SwitchStatus } from '..'
import {
	DoubleLeftOutlined,
	DoubleRightOutlined,
	LogoutOutlined,
	SettingOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import { usersAPI } from 'api/api'
import { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import Avatar from '../../assets/images/avatarka.png'
import adminIcon from '../../assets/images/gridicons.svg'
import repIcon from '../../assets/images/reporting.svg'
import terminalIcon from '../../assets/images/window.svg'
import {
	getUser,
	loadingStatus,
	logOut,
	setAsideActive,
	useAppDispatch,
	useAppSelector,
} from '../../store'
import {
	ADMIN_PATH,
	REPORTS_PATH,
	SETTINGS_PATH,
	TERMINALS_PATH,
	openErrorNotification,
} from '../../utils'

export const Aside = () => {
	const { isAsideActive: asideIsOpen } = useAppSelector((state) => state.app)
	const { currentUser } = useAppSelector((state) => state.users)
	const { role, name, userId } = useAppSelector((state) => state.auth)
	const [avatarImg, setAvatarImg] = useState<any>(Avatar)
	const dispatch = useAppDispatch()

	const toggleAsideOpen = () => {
		localStorage.setItem('menuArm', `${!asideIsOpen}`)
		dispatch(setAsideActive(!asideIsOpen))
	}

	const loadAvatar = async () => {
		if (currentUser?.photoId) {
			try {
				dispatch(loadingStatus(true))
				const { data } = await usersAPI.getFoto({
					PhotoId: currentUser.photoId,
				})
				const blob = new Blob([data])
				setAvatarImg(URL.createObjectURL(blob))
			} catch (e) {
				console.error(e)
				openErrorNotification('Ошибка получения фото')
			} finally {
				dispatch(loadingStatus(false))
			}
		} else {
			setAvatarImg(Avatar)
		}
	}

	const onLogOut = async () => {
		await usersAPI.switchStatus(false)
		dispatch(logOut())
	}
	useEffect(() => {
		loadAvatar()
	}, [currentUser])

	useEffect(() => {
		if (userId) {
			dispatch(getUser({ UserId: userId }))
		}
	}, [userId])

	return (
		<aside className={`aside ${asideIsOpen === false ? '_close' : ''}`}>
			<button className="aside__toggle" type="button" onClick={toggleAsideOpen}>
				{asideIsOpen ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
			</button>
			<div className="aside__top-wrap">
				<div className="aside__top ">
					<div className="aside__top-name">
						<Link
							className="aside__img-wrap"
							to={role !== 'Statistician' ? TERMINALS_PATH : REPORTS_PATH}
						>
							<img className="aside__img" src={avatarImg} alt="avatar" />
						</Link>
						{asideIsOpen && (
							<div className="aside__top-text">
								<div className="aside__text-name">{name ? name : 'Ошибка'}</div>
								<div className="aside__bold-text">{currentUser?.login}</div>
								<div className="aside__bold-text">
									{role === 'Admin' ? 'Администратор' : 'Оператор'}
								</div>
							</div>
						)}
					</div>
					{role !== 'Admin' && userId && <SwitchStatus userId={userId} />}
				</div>

				<ul className="aside__list">
					{role !== 'Statistician' && (
						<li className="aside__item">
							<NavLink to={TERMINALS_PATH} className={'aside__link'}>
								<img
									className="aside__link-icon"
									src={terminalIcon}
									alt="aside__img"
								/>
								{asideIsOpen && 'Терминалы'}
							</NavLink>
						</li>
					)}

					{(role === 'Admin' || role === 'Statistician') && (
						<li className="aside__item">
							<NavLink to={REPORTS_PATH} className={'aside__link'}>
								<img
									className="aside__link-icon"
									src={repIcon}
									alt="aside__img"
								/>
								{asideIsOpen && 'Отчеты'}
							</NavLink>
						</li>
					)}
					{role === 'Admin' && (
						<li className="aside__item">
							<NavLink to={SETTINGS_PATH} className={'aside__link'}>
								<SettingOutlined
									style={{ fontSize: '30px' }}
									className="aside__link-icon"
								/>
								{asideIsOpen && 'Настройки'}
							</NavLink>
						</li>
					)}
					{role === 'Admin' && (
						<li className="aside__item">
							<NavLink to={ADMIN_PATH} className={'aside__link'}>
								<img
									className="aside__link-icon"
									src={adminIcon}
									alt="aside__img"
								/>
								{asideIsOpen && 'Панель администратора'}
							</NavLink>
						</li>
					)}
				</ul>
			</div>
			<div className="aside__bottom">
				<Button onClick={onLogOut} className="aside__bottom-btn">
					{asideIsOpen ? (
						'Выйти'
					) : (
						<LogoutOutlined style={{ fontSize: '24px' }} />
					)}
				</Button>
			</div>
		</aside>
	)
}
