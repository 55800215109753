import { DatePicker, Pagination, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch } from 'store/hooks'

import { getFeedBackListParams } from '@/utils'

import { appealsAPI } from '@/api'

const { RangePicker } = DatePicker

type feedbackType = {
	id: string
	abonentName: string
	abonentPhoneNumber: string
	abonentPerconalAcc: string
	createdAt: string
	terminalId: string
	terminalName: string
	terminalAddress: string
}

export const FeedBackList = () => {
	const [dataList, setDataList] = useState<
		undefined | { feedbacks: Array<feedbackType>; totalElementCount: number }
	>(undefined)

	const dispatch = useAppDispatch()
	const [params, setParams] = useState<getFeedBackListParams>({
		PageNumber: 1,
		PageSize: 10,
	})

	const fetchData = async () => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await appealsAPI.getListFeedBack({ ...params })
			setDataList(data)
		} catch (e) {
			console.error(e)
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const columns = [
		{
			title: 'Имя абонента',
			dataIndex: 'abonentName',
			key: 'abonentName',
		},
		{
			title: 'Номер телефона',
			dataIndex: 'abonentPhoneNumber',
			key: 'abonentPhoneNumber',
		},
		{
			title: 'Лицевой счет',
			dataIndex: 'abonentPerconalAcc',
			key: 'abonentPerconalAcc',
		},
		{
			title: 'Дата и время',
			render: (e: any) => (
				<div>{moment(e.createdAt).format('YYYY-MM-DD, HH:mm:ss')}</div>
			),
		},

		{
			title: 'Имя терминала',
			dataIndex: 'terminalName',
			key: 'terminalName',
		},
		{
			title: 'Адрес',
			dataIndex: 'terminalAddress',
			key: 'terminterminalAddressalName',
			width: 200,
		},
	]

	useEffect(() => {
		fetchData()
	}, [params])

	const onDateChange = (date: any, dateString: any) => {
		setParams({
			...params,
			StartedAt: dateString[0],
			EndedAt: dateString[1],
			PageNumber: 1,
		})
	}

	const onPageChange = (newPage: number, newPageSize?: number) => {
		setParams((prevParams) => ({
			...prevParams,
			PageNumber: newPage,
			PageSize: newPageSize || prevParams.PageSize,
		}))
	}

	return (
		<div className="readings">
			<div className="readings__title">Список обратных звонков</div>
			<div className="readings__filter">
				<div className="readings__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
			</div>
			<Table
				dataSource={dataList?.feedbacks}
				columns={columns}
				pagination={false}
			/>
			<Pagination
				className="readings__pagination"
				current={params.PageNumber}
				pageSize={params.PageSize}
				total={dataList?.totalElementCount}
				onChange={onPageChange}
			/>
		</div>
	)
}
