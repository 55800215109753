import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface InitialStateType {
	isChecking: boolean
	isCalling: boolean
	typeCall: 'operator' | 'terminal' | null
	appealId: string | null
	callRecordId: string | null
}

const initialState: InitialStateType = {
	isChecking: false,
	isCalling: false,
	typeCall: null,
	appealId: null,
	callRecordId: null,
}

export const callReducer = createSlice({
	name: 'call',
	initialState,
	reducers: {
		setIsChecking: (state, action: PayloadAction<boolean>) => {
			state.isChecking = action.payload
		},
		setIsCalling: (state, action: PayloadAction<boolean>) => {
			state.isCalling = action.payload
		},
		setTypeCall: (
			state,
			action: PayloadAction<'operator' | 'terminal' | null>
		) => {
			state.typeCall = action.payload
		},
		setAppealId: (state, action: PayloadAction<string | null>) => {
			state.appealId = action.payload
		},
		setCallRecordId: (state, action: PayloadAction<string | null>) => {
			state.callRecordId = action.payload
		},
	},
})

export const {
	setIsChecking,
	setIsCalling,
	setTypeCall,
	setAppealId,
	setCallRecordId,
} = callReducer.actions

export default callReducer.reducer
