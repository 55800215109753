import { Input } from 'antd'
import dayjs from 'dayjs'
import React, { useRef, useState } from 'react'
import { loadingStatus } from 'store/app'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { getTerminal } from '@/store'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

export default function DefaultWorkTime({
	id,
	fetch,
}: {
	id: string
	fetch: () => void
}) {
	const dispatch = useAppDispatch()
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)

	const [startTime, setStartTime] = useState(
		terminalInfo
			? dayjs(terminalInfo.startWorkTime, 'HH:mm:ss').format('HH:mm')
			: ''
	)
	const [endTime, setEndTime] = useState(
		terminalInfo
			? dayjs(terminalInfo.endWorkTime, 'HH:mm:ss').format('HH:mm')
			: ''
	)
	const [error, setError] = useState(false)

	const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null)

	// Функция для сохранения данных
	const saveWorkTime = (start: string, end: string) => {
		if (!start || !end) {
			setError(true)
			return
		}
		setError(false)

		// Очищаем предыдущий таймер, если пользователь быстро меняет значения
		if (saveTimeoutRef.current) {
			clearTimeout(saveTimeoutRef.current)
		}

		// Устанавливаем новый таймер для сохранения через 1 секунду
		saveTimeoutRef.current = setTimeout(async () => {
			dispatch(loadingStatus(true))
			try {
				await terminalAPI.setDefaultWorkTime({
					terminalId: id,
					startWorkTime: dayjs(start, 'HH:mm').format('HH:mm:ss'),
					endWorkTime: dayjs(end, 'HH:mm').format('HH:mm:ss'),
				})
				openSuccessNotification('Дефолтное время работы обновлено')
				fetch()
				dispatch(getTerminal(id))
			} catch (e) {
				console.error(e)
				openErrorNotification('Ошибка при установке дефолтного времени')
			} finally {
				dispatch(loadingStatus(false))
			}
		}, 1000) // Задержка в 1 секунду перед сохранением
	}

	return (
		<>
			<div className="work-time__title _h4">Стандартное время работы</div>
			<div>Изменить время работы</div>

			{terminalInfo && (
				<>
					<div className="work-time__inputs">
						<Input
							className="work-time__input"
							type="time"
							value={startTime}
							onChange={(e) => {
								setStartTime(e.target.value)
								saveWorkTime(e.target.value, endTime)
							}}
						/>
						<Input
							className="work-time__input"
							type="time"
							value={endTime}
							onChange={(e) => {
								setEndTime(e.target.value)
								saveWorkTime(startTime, e.target.value)
							}}
						/>
					</div>
					<div className={`work-time__default-error ${error && '_active'}`}>
						Заполните оба поля времени
					</div>
				</>
			)}
		</>
	)
}
