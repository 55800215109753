import { Tabs, TabsProps } from 'antd'
import System from 'components/System/System'

import { Operators } from '@/components'

const items: TabsProps['items'] = [
	{
		key: '1',
		label: 'Операторы',
		children: (
			<div className="administration__inner">
				<Operators />
			</div>
		),
	},
	{
		key: '2',
		label: 'Справочники',
		children: <div className="administration__inner"></div>,
	},
	{
		key: '3',
		label: 'Система',
		children: (
			<div className="administration__inner">
				<System />
			</div>
		),
	},
]

export function Administration() {
	return (
		<div className="administration">
			<div className="administration__title title">Панель администратора</div>
			<Tabs
				defaultActiveKey="1"
				items={items}
				type="card"
				size="large"
				className="administration__tabs"
			/>
		</div>
	)
}
