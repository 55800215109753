import { DatePicker, Input, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { getReadingsList } from 'store/appeals'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import { getReadingsListType } from '@/utils'

const { RangePicker } = DatePicker
const { Search } = Input

const Readings = () => {
	const data = useAppSelector((state) => state.appeals.listReadings)
	console.log(data)
	const dispatch = useAppDispatch()
	const [params, setParams] = useState<getReadingsListType>({})

	const fetchData = async () => {
		dispatch(getReadingsList({}))
	}

	const columns = [
		{
			title: 'Лицевой счет',
			dataIndex: 'abonent',
			key: 'abonent',
			render: (e: any) => <span>{e?.personalAcc}</span>,
		},
		{
			title: 'Имя терминала',
			dataIndex: 'terminal',
			key: 'terminal',
			render: (e: any) => <span>{e?.name}</span>,
		},
		{
			title: 'Дата и время',
			render: (e: any) => (
				<div>{moment(e.createdAt).format('YYYY-MM-DD, HH:mm:ss')}</div>
			),
		},
		{
			title: 'Показания',
			dataIndex: 'reading',
			key: 'reading',
		},
		{
			title: 'В реестре',
			dataIndex: 'isSynced',
			key: 'isSynced',
			render: (e: any) => <span>{e ? 'Да' : 'Нет'}</span>,
		},
	]

	useEffect(() => {
		fetchData()
	}, [])

	useEffect(() => {
		dispatch(getReadingsList(params))
	}, [params])

	const onDateChange = (date: any, dateString: any) => {
		setParams({ ...params, StartedAt: dateString[0], EndedAt: dateString[1] })
	}

	const onSearch = (value: string) => {
		setParams({ ...params, PersonalAcc: value })
	}

	return (
		<div className="readings">
			<div className="readings__title">Список показаний</div>
			<div className="readings__filter">
				<div className="readings__filter-text">Фильтр по дате:</div>
				<RangePicker onChange={onDateChange} />
				<div className="readings__filter-text">Поиск по лицевому счету:</div>
				<Search
					placeholder="Поиск по лицевому счету"
					onSearch={onSearch}
					style={{ width: 200 }}
				/>
			</div>
			<Table dataSource={data} columns={columns} />
		</div>
	)
}

export default Readings
