import { Button, Input, Modal, notification } from 'antd'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { RtspSession, VncSession, WebRTCSession } from '@/components'

import {
	documentsItem,
	setAppealId,
	setCallRecordId,
	setIsCalling,
	setTypeCall,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { nameDevice } from '@/utils'

import { appealsAPI, axiosPrivate, terminalAPI, terminalCallAPI } from '@/api'

import { TerminalDocsAppeal } from '../docs/TerminalDocsAppeal'
import { PrinterModal, ScannerModal } from '../modal'

import { Equipment } from './Equipments'

export const TerminalMain = memo(function TerminalMain({
	id,
	terminalId,
	setIsFull,
}: {
	id: string
	terminalId: string
	setIsFull: (value: boolean) => void
}) {
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const { appealId } = useAppSelector((state) => state.call)
	const dispatch = useAppDispatch()
	const router = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()

	const [modalPrinter, setModalPrinter] = useState(false)
	const [modalScanner, setModalScanner] = useState(false)
	const [cameraType, setCameraType] = useState<'btn' | 'webrtc' | 'camera'>(
		'btn'
	)
	const [vncType, setVncType] = useState<'btn' | 'vnc'>('btn')
	const [tableData, setTableData] = useState<documentsItem[]>([])
	const [isAppeal, setIsAppeal] = useState(false)
	const [isSmall, setIsSmall] = useState(false)

	const webrtcRef = useRef<any>(null)

	useEffect(() => {
		if (searchParams.get('session')) {
			const appealId = searchParams.get('session')
			dispatch(setAppealId(appealId))
			dispatch(setTypeCall('operator'))
			setCameraType('webrtc')
			setIsAppeal(true)
			setIsFull(true)
		}
	}, [searchParams, setIsFull])

	const getDataTable = useCallback(async () => {
		try {
			const { data } = await terminalAPI.getDocuments({
				TerminalId: id,
				AppealId: appealId!,
			})
			setTableData(data)
		} catch (error) {
			console.error(error)
		}
	}, [id, appealId])

	const {
		control,
		formState: { errors },
		getValues,
		reset,
	} = useForm<{
		abonentPersonalAcc: string
	}>({
		mode: 'onChange',
	})

	const handleSessionEnd = useCallback(async () => {
		if (!errors.abonentPersonalAcc?.message) {
			await terminalCallAPI.end_call()
			await appealsAPI.editAfterCall({
				appealId: appealId!,
				abonentPersonalAcc: getValues('abonentPersonalAcc'),
			})
			webrtcRef.current?.deleteRoom()
			reset()
		}
	}, [appealId])

	const handleOperatorCall = useCallback(async () => {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices()
			const hasCamera = devices.some((device) => device.kind === 'videoinput')
			const hasMicrophone = devices.some(
				(device) => device.kind === 'audioinput'
			)

			if (!hasCamera) {
				notification.warning({
					message: 'Нет доступа к камере',
					description: 'Пожалуйста, подключите камеру и попробуйте снова.',
				})
				return
			}

			if (!hasMicrophone) {
				notification.warning({
					message: 'Нет доступа к микрофону',
					description: 'Пожалуйста, подключите микрофон и попробуйте снова.',
				})
				return
			}

			const { data } = await terminalCallAPI.receive_operator_call(
				terminalInfo!.terminalId
			)
			dispatch(setCallRecordId(data.callRecordId))
			dispatch(setAppealId(data.appealId))
			dispatch(setTypeCall('operator'))
			setCameraType('webrtc')
			setIsAppeal(true)
			setIsFull(true)
			setSearchParams({
				session: data.appealId,
			})
		} catch (error: any) {
			if (
				error.response?.data?.message ===
				'Selected terminal already awaits call'
			) {
				notification.warning({
					message: 'Выбранный терминал уже ожидает звонка',
				})
			} else if (error.response?.data?.message === 'Terminal is in call') {
				notification.warning({
					message: 'Терминал находится в звонке',
				})
			} else {
				console.error(error)
			}
		}
	}, [dispatch, terminalInfo, setIsFull])

	const cameraBlock = useMemo(
		() => ({
			btn: (
				<Button type="primary" onClick={() => setCameraType('camera')}>
					Подключиться к камере
				</Button>
			),
			webrtc: (
				<WebRTCSession
					id={id}
					terminalId={terminalId}
					onClose={() => {
						setIsAppeal(false)
						setIsSmall(false)
						setIsFull(false)
						setCameraType('btn')
						dispatch(setIsCalling(false))
						dispatch(setAppealId(null))
						router(`/terminals/${id}`, { replace: true })
					}}
					ref={webrtcRef}
				/>
			),
			camera: <RtspSession id={id} onClose={() => setCameraType('btn')} />,
		}),
		[terminalId, id]
	)

	const vncBlock = useMemo(
		() => ({
			btn: (
				<Button type="primary" onClick={() => setVncType('vnc')}>
					Подключиться
				</Button>
			),
			vnc: <VncSession id={id} onClose={() => setVncType('btn')} />,
		}),
		[]
	)

	return (
		<div className="terminal__main-wrap">
			<div className="terminal__main-wrap-item_2">
				<div
					className={`terminal__main-camera ${
						cameraType !== 'btn' ? 'terminal__main-camera-active' : ''
					}`}
				>
					{cameraBlock[cameraType]}
				</div>
				<div className="terminal__devices">
					<h2>Мониторинг устройств</h2>
					<div className="terminal__devices-wrap">
						{terminalInfo?.devices?.map((device) => (
							<Equipment
								key={device.id}
								title={nameDevice[device.type]?.name}
								status={device.status}
								icon={nameDevice[device.type]?.icon}
							/>
						))}
					</div>
				</div>
			</div>
			<div className="terminal__main-wrap-item_3">
				<div
					className={`terminal__main-vnc ${vncType !== 'btn' ? 'active' : ''}`}
				>
					{vncBlock[vncType]}
				</div>
				{isAppeal ? (
					<>
						<form className="terminal__main-subscriber">
							<h2>Лицевой счет</h2>
							<Controller
								name="abonentPersonalAcc"
								control={control}
								rules={{
									minLength: {
										value: 8,
										message: 'Минимум 8 символов',
									},
									maxLength: {
										value: 12,
										message: 'Максимум 12 символов',
									},
									pattern: {
										value: /^([0-9]{8}|[0-9]{12})$/i,
										message: '8 или 12 цифр',
									},
								}}
								render={({ field }) => (
									<Input
										id="abonentPersonalAcc"
										maxLength={12}
										max={12}
										minLength={8}
										{...field}
									/>
								)}
							/>
							{errors?.abonentPersonalAcc && (
								<div className="input__error">
									{errors.abonentPersonalAcc?.message
										? errors.abonentPersonalAcc?.message
										: 'Ошибка'}
								</div>
							)}
						</form>
						<TerminalDocsAppeal data={tableData} />
						<div className="terminal__main-appeal">
							<div>
								<Button type="primary" onClick={() => setModalPrinter(true)}>
									Распечатать
								</Button>
								<Button type="primary" onClick={() => setModalScanner(true)}>
									Отсканировать
								</Button>
							</div>

							<Button
								type="primary"
								className="green"
								onClick={() => {
									webrtcRef.current?.sendMessage({
										type: 'change-size-window',
										room: terminalId,
										size: !isSmall,
									})
									setIsSmall(!isSmall)
								}}
							>
								{isSmall
									? 'Развернуть видео на терминале'
									: 'Свернуть видео на терминале'}
							</Button>

							<Button
								color="danger"
								variant="solid"
								onClick={handleSessionEnd}
								disabled={errors.abonentPersonalAcc ? true : false}
							>
								Завершить сеанс
							</Button>
						</div>
					</>
				) : (
					<div className="terminal__main-appeal">
						<Button
							type="primary"
							className="green"
							onClick={handleOperatorCall}
						>
							Начать сеанс связи
						</Button>
					</div>
				)}
			</div>

			<Modal
				open={modalPrinter}
				title="Принтер"
				centered
				className="terminal__modal"
				onCancel={() => setModalPrinter(false)}
				footer={null}
				destroyOnClose
			>
				<PrinterModal name="HP LaserJet 1050" id={id} />
			</Modal>
			<Modal
				open={modalScanner}
				title="Сканер"
				centered
				className="terminal__modal"
				onCancel={() => {
					setModalScanner(false)
					getDataTable()
				}}
				footer={null}
				destroyOnClose
			>
				<ScannerModal name="Canon" id={id} />
			</Modal>
		</div>
	)
})
