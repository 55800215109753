import { Outlet } from 'react-router-dom'

import { Aside } from '@/components'

import { CallProvider, StatusProvider } from '@/providers'

import { useAppSelector } from '@/store'

const MainLayout = () => {
	const asideIsOpen = useAppSelector((state) => state.app.isAsideActive)

	return (
		<>
			<StatusProvider>
				<main className={`main ${!asideIsOpen === true ? '_aside-close' : ''}`}>
					<Aside />
					<div className="main__right">
						<CallProvider>
							<Outlet />
						</CallProvider>
					</div>
				</main>
			</StatusProvider>
		</>
	)
}

export default MainLayout
