import { loadingStatus } from '..'

import {
	addAppeals,
	getAccountRequestsReportType,
	getAppeals,
	getPaymentsListType,
	getReadingsListType,
	openErrorNotification,
	openSuccessNotification,
} from '@/utils'

import { abonentAPI, appealsAPI } from '@/api'

import {
	setAppealsList,
	setApplicationsAccount,
	setCurrentAppeals,
	setListPayments,
	setListReadings,
	setStatusList,
	setTypesList,
} from './appealsSlice'

export const getStatusTypesList = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		const statusList = await appealsAPI.getStatutList()
		const typesList = await appealsAPI.getTypesList()
		dispatch(setStatusList(statusList.data))
		dispatch(setTypesList(typesList.data))
	} catch (e: any) {
		if (e.response.data) {
			openErrorNotification('Ошибка получения списка терминалов')
		}
	}
	dispatch(loadingStatus(false))
}

export const addNewAppeal =
	({ type, status, comment, abonentId, terminalId }: addAppeals) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await appealsAPI.addAppeals({
				type,
				status,
				comment,
				abonentId,
				terminalId,
			})
			const appeal = await appealsAPI.getAppeals(response.data)

			dispatch(setCurrentAppeals(appeal.data))
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка создание обращения')
			}
		}
		dispatch(loadingStatus(false))
	}

export const getAppealsList =
	({
		Type,
		Status,
		CreatedStart,
		CreatedEnd,
		AbonentId,
		AstipId,
		UserId,
	}: getAppeals) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await appealsAPI.getList({
				...(Type && { Type }),
				...(Status && { Status }),
				...(CreatedStart && { CreatedStart }),
				...(CreatedEnd && { CreatedEnd }),
				...(AbonentId && { AbonentId }),
				...(AstipId && { AstipId }),
				...(UserId && { UserId }),
			})
			dispatch(
				setAppealsList(response.data.map((e: any) => ({ ...e, key: e.id })))
			)
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения списка обращения')
			}
		}
		dispatch(loadingStatus(false))
	}

export const getAppeal =
	({ AppealId }: { AppealId: string }) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const appeal = await appealsAPI.getAppeals(AppealId)

			dispatch(setCurrentAppeals(appeal.data))
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения информации о обращении')
			}
		}
		dispatch(loadingStatus(false))
	}

export const editAppeal =
	({
		AppealId,
		comment,
		appealStatus,
	}: {
		AppealId: string
		comment: string | null
		appealStatus: number
	}) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			await appealsAPI.setComment({
				appealId: AppealId,
				comment: comment || null,
			})
			await appealsAPI.setStatus({ appealId: AppealId, appealStatus })
			const appeal = await appealsAPI.getAppeals(AppealId)

			dispatch(setCurrentAppeals(appeal.data))

			// const response = await appealsAPI.getList({})
			// dispatch(
			// 	setAppealsList(response.data.map((e: any) => ({ ...e, key: e.id })))
			// )

			openSuccessNotification('Данные обращения изменены')
		} catch (e: any) {
			if (e?.response?.data) {
				openErrorNotification('Ошибка получения информации о обращении')
			}
			console.error(e)
		}
		dispatch(loadingStatus(false))
	}

export const getPaymentsList =
	({
		TerminalName,
		TerminalId,
		StartedAt,
		EndedAt,
		PersonalAcc,
		Status,
		PaymentType,
	}: getPaymentsListType) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await appealsAPI.getPaymentsList({
				...(TerminalName && { TerminalName }),
				...(TerminalId && { TerminalId }),
				...(StartedAt && { StartedAt }),
				...(EndedAt && { EndedAt }),
				...(PersonalAcc && { PersonalAcc }),
				...(Status && { Status }),
				...(PaymentType && { PaymentType }),
			})
			dispatch(
				setListPayments(response.data.map((e: any) => ({ ...e, key: e.id })))
			)
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения списка обращения')
			}
		}
		dispatch(loadingStatus(false))
	}

export const getReadingsList =
	({
		TerminalName,
		TerminalId,
		StartedAt,
		EndedAt,
		PersonalAcc,
		PaymentType,
	}: getReadingsListType) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await abonentAPI.getReadings({
				...(TerminalName && { TerminalName }),
				...(TerminalId && { TerminalId }),
				...(StartedAt && { StartedAt }),
				...(EndedAt && { EndedAt }),
				...(PersonalAcc && { PersonalAcc }),
				...(PaymentType && { PaymentType }),
			})
			dispatch(
				setListReadings(response.data.map((e: any) => ({ ...e, key: e.id })))
			)
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения списка показаний')
			}
		}
		dispatch(loadingStatus(false))
	}

export const getAccountRequestsReport =
	({
		PageNumber,
		PageSize,
		CreatedEnd,
		CreatedStart,
		SearchString,
	}: getAccountRequestsReportType) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await appealsAPI.getAccountRequestsReport({
				PageNumber,
				PageSize,
				CreatedEnd,
				CreatedStart,
				SearchString,
			})
			dispatch(setApplicationsAccount(response.data))
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения заявок на счет')
			}
		}
		dispatch(loadingStatus(false))
	}
