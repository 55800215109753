import { Button, Tabs, notification } from 'antd'
import { axiosPrivate, axiosPrivateWEBRTC } from 'api/axios'
import TerminalJurnal from 'components/Terminal/TerminalJorney/TerminalJurnal'
import WorkTime from 'components/Terminal/TerminalSettings/WorkTime'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { TerminalAppeals, TerminalMain, TerminalSettings } from '@/components'

import {
	getTerminal,
	getTerminalConfig,
	setTerminal,
	setTerminalConfig,
	useAppDispatch,
	useAppSelector,
} from '@/store'

const tabs = ['Основное', 'Настройки', 'Журнал', 'Обращения', 'График работы']

export function TerminalPage() {
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const [isFull, setIsFull] = useState<boolean>(false)
	const [page, setPage] = useState<string>('Основное')
	const [searchParams] = useSearchParams()
	const [isCallingOperator, setIsCallingOperator] = useState(false)

	const role = useAppSelector((state) => state.auth.role)

	const dispatch = useAppDispatch()

	const params = useParams()
	const id = params.id

	useEffect(() => {
		const fetchData = async () => {
			const { data } = await axiosPrivate.get(
				'/terminal_call/get_terminal_call_info',
				{
					params: {
						TerminalId: terminalInfo?.terminalId,
					},
				}
			)
			console.log(data)
			setIsCallingOperator(data.isCallExists)
		}
		terminalInfo?.terminalId && role === 'Admin' && fetchData()
	}, [terminalInfo])

	useEffect(() => {
		if (id) {
			dispatch(getTerminal(id))
			role === 'Admin' && dispatch(getTerminalConfig(id))
		}
		return () => {
			dispatch(setTerminal(null))
			dispatch(setTerminalConfig(null))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (searchParams.get('session')) {
			setPage('Основное')
		}
	}, [searchParams])

	const handleDeleteRoom = async () => {
		try {
			await axiosPrivateWEBRTC.get(
				`/api/call/close/${terminalInfo?.terminalId}`
			)
			setIsCallingOperator(false)
			notification.success({
				message: 'Успешно',
				description: 'Звонок завершен',
			})
		} catch (error: any) {
			console.error(error)
		}
	}

	return (
		<>
			{terminalInfo && (
				<>
					<Tabs
						defaultActiveKey="1"
						type="card"
						size="large"
						onChange={setPage}
						className="terminal__tabs"
						activeKey={page}
						items={tabs
							.filter((item) => {
								if (
									role !== 'Admin' &&
									(item === 'Настройки' ||
										item === 'График работы' ||
										item === 'Журнал')
								) {
									return false
								}
								return true
							})
							.map((string) => {
								return {
									label: `${string}`,
									key: `${string}`,
								}
							})}
					/>
					<div className={`terminal ${isFull ? 'terminal__full' : ''}`}>
						<div className="terminal__header">
							<div>
								<h2 className="title">{terminalInfo.name}</h2>

								<p>IP-адрес терминала: {terminalInfo.currentIP}</p>
								<p>Адрес терминала: {terminalInfo.address}</p>
								<p>Id терминала: {terminalInfo.terminalId}</p>
							</div>
							<div>
								{isCallingOperator &&
									role === 'Admin' &&
									page === 'Основное' && (
										<Button
											danger
											variant="dashed"
											type="primary"
											onClick={handleDeleteRoom}
										>
											Закрыть звонок
										</Button>
									)}
							</div>
						</div>
						{id && (
							<>
								{page === 'Основное' && (
									<TerminalMain
										id={terminalInfo.id}
										terminalId={terminalInfo.terminalId}
										setIsFull={setIsFull}
									/>
								)}
								{page === 'Настройки' && role === 'Admin' && (
									<TerminalSettings id={id} />
								)}

								{page === 'Журнал' && role === 'Admin' && (
									<TerminalJurnal id={id} />
								)}
								{page === 'Обращения' && <TerminalAppeals id={id} />}
								{page === 'График работы' && role === 'Admin' && (
									<WorkTime id={id} />
								)}
							</>
						)}
					</div>
				</>
			)}
		</>
	)
}
