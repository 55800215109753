import { API_URL } from 'configs/url'
import { useEffect } from 'react'
import { setStatus } from 'store/auth'
import { useAppDispatch, useAppSelector } from 'store/hooks'

interface Props {
	children: JSX.Element
}

export const StatusProvider = ({ children }: Props) => {
	const { userId } = useAppSelector((state) => state.auth)
	const dispatch = useAppDispatch()

	useEffect(() => {
		let ws: WebSocket | null = null

		const connect = () => {
			ws = new WebSocket(
				`${API_URL}/users/switch_status_socket?UserId=${userId}`
			)

			ws.onopen = () => {
				if (ws && ws.readyState === WebSocket.OPEN) {
					sendMessage(JSON.stringify({ IsActive: true }))
						.then(() => {
							console.log('Activation message sent')
							dispatch(setStatus(true))
						})
						.catch((error) => {
							console.error('Error sending activation message:', error)
						})
				}
			}
		}

		const sendMessage = (message: string): Promise<void> => {
			return new Promise((resolve, reject) => {
				if (ws && ws.readyState === WebSocket.OPEN) {
					ws.send(message)
					resolve()
				} else {
					reject(new Error('WebSocket is not open'))
				}
			})
		}

		connect()

		const handleBeforeUnload = async () => {
			if (ws && ws.readyState === WebSocket.OPEN) {
				await sendMessage(JSON.stringify({ IsActive: false }))
				console.log('Message sent, closing socket...')
				ws.close()
			}
		}

		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)

			if (ws && ws.readyState === WebSocket.OPEN) {
				sendMessage(JSON.stringify({ IsActive: false }))
					.then(() => {
						console.log('Message sent, closing socket...')
						ws?.close()
					})
					.catch((error) => {
						console.error('Error sending message:', error)
						ws?.close()
					})
			}
		}
	}, [userId])

	return <>{children}</>
}
